import { Column, RowAlignedItems } from '@components/LinearLayout';
import {
  type BookingCheckDetails,
  type DiscountType,
  type EventType,
  type PaymentMethodType,
  type QueryResponseType,
  type Ticket,
  type TicketGroup,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  type TicketOptionsInput,
  usePublicTicketGroups,
} from '@utils/apiHooks';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CenteredText,
  CentralContent,
  CurrencySwitcher,
  LeftSideContent,
  RightSideContent,
  TicketCardItemWrapper,
  TicketConfigHeader,
} from './styles';

import Checkbox from '@components/Checkbox';
import DiscountSection from '@components/DiscountSection';
import ErrorSection from '@components/ErrorSection';
import EventDetails from '@components/EventDetails';
import { PSmallBold } from '@components/Typography';
import TabSwitcher from '@composites/TabSwitcher';
import TicketCardItem from '@composites/TicketCardItem';
import { DISPLAY_MODE } from '@constants/generic';
import TicketSkeletonContainer from '@pages/Purchase/components/TicketDetailsStep/components/TicketSkeletonContainer';
import { checkIfDiscountAppliesMinMax } from '@utils/checkIfDiscountAppliesMinMax';
import { extractSearchParams } from '@utils/fn';
import { useHookParams } from '@utils/hooks';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface TicketDetailsStepProps {
  eventDetails: QueryResponseType<EventType>;
  ticketsDetails: QueryResponseType<Ticket[]>;
  paymentMethodsDetails: QueryResponseType<PaymentMethodType[]>;
  currencies: string[];
  discount: DiscountType | undefined;
  setDiscount: Dispatch<SetStateAction<DiscountType | undefined>>;
  bookingsCheckDetails: QueryResponseType<BookingCheckDetails>;
  selectedTicketsWithOptions: TicketOptionsInput[];
  setSelectedTicketsWithOptions: Dispatch<SetStateAction<TicketOptionsInput[]>>;
}

const TicketDetailsStep = ({
  eventDetails,
  ticketsDetails,
  paymentMethodsDetails,
  currencies,
  discount,
  setDiscount,
  bookingsCheckDetails,
  selectedTicketsWithOptions,
  setSelectedTicketsWithOptions,
}: TicketDetailsStepProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get('la') ?? 'en';
  const [code, groupTicket, type, selectedCurrency] = useHookParams([
    'tc',
    'gt',
    'ty',
    'cu',
  ]);
  const [error, setError] = useState<string>('');

  const isIframe = useMemo(() => type === 'iframe', [type]);

  const {
    data: event,
    loading: isEventLoading,
    error: isEventError,
  } = eventDetails ?? {};

  const { data: tickets, loading: isTicketsLoading } = ticketsDetails ?? {};
  const {
    data: ticketGroupsData,
    isLoading: isTicketGroupLoading,
    error: ticketGroupsError,
  } = usePublicTicketGroups({ eventId: event?.id, language });

  const ticketGroups = ticketGroupsData?.ticketGroups?.result?.data?.sort(
    (a: TicketGroup, b: TicketGroup) => a?.order - b?.order
  ) as TicketGroup[];

  const [activeFilter, setActiveFilter] = useState<TicketGroup>(
    ticketGroups?.[0]
  );
  const ticketsIncludedInTicketGroups = useMemo(() => {
    if (ticketGroups?.length > 0 && activeFilter) {
      return tickets?.filter(
        ticket =>
          activeFilter?.list?.findIndex(ticketId => ticketId === ticket.id) > -1
      );
    }
    return tickets;
  }, [tickets, ticketGroups, activeFilter]);

  const displayPriority = useMemo(() => {
    if (code) return DISPLAY_MODE.TICKET;
    else if (groupTicket) return DISPLAY_MODE.TICKET_GROUP;
    else if (ticketGroups?.length > 0) return DISPLAY_MODE.TICKET_GROUPS;
    return DISPLAY_MODE.ALL_TICKETS;
  }, [code, groupTicket, ticketGroups?.length]);

  const tabNavigationTicketGroups = ticketGroups?.map(ticketGroup => {
    return {
      label: ticketGroup.name,
      onItemClick: () => {
        setActiveFilter(ticketGroup);
      },
    };
  });

  useEffect(() => {
    const ticket = tickets?.find(e => e.code === code);
    if (code && ticket) {
      setSelectedTicketsWithOptions([
        {
          index: 0,
          isAssignedToMe: false,
          ticket: ticket?.id,
          options: [],
        },
      ]);
    }
    return () => {
      setSelectedTicketsWithOptions([]);
    };
  }, [code, setSelectedTicketsWithOptions, tickets]);

  const BookingTickets = useMemo(() => {
    const ticketGroup =
      ticketGroups?.find(e => e.slug === groupTicket)?.list ?? [];
    const ticket = tickets?.find(e => e.code === code);

    switch (displayPriority) {
      case DISPLAY_MODE.TICKET:
        return (
          <>
            {ticket ? (
              <TicketCardItem
                ticket={ticket}
                selectedTicketsWithOptions={selectedTicketsWithOptions}
                setSelectedTicketsWithOptions={setSelectedTicketsWithOptions}
                discount={
                  checkIfDiscountAppliesMinMax(
                    selectedTicketsWithOptions,
                    discount
                  )
                    ? discount
                    : undefined
                }
              />
            ) : (
              <CenteredText>{t('no-tickets-available')}</CenteredText>
            )}
          </>
        );
      case DISPLAY_MODE.TICKET_GROUP:
        return tickets?.filter(e => ticketGroup?.includes(e.id))?.length >=
          0 ? (
          <>
            {tickets
              ?.filter(e => ticketGroup?.includes(e.id))
              ?.map((ticket: Ticket) => (
                <TicketCardItem
                  key={ticket?.id}
                  ticket={ticket}
                  selectedTicketsWithOptions={selectedTicketsWithOptions}
                  setSelectedTicketsWithOptions={setSelectedTicketsWithOptions}
                  discount={
                    checkIfDiscountAppliesMinMax(
                      selectedTicketsWithOptions,
                      discount
                    )
                      ? discount
                      : undefined
                  }
                />
              ))}
          </>
        ) : (
          <CenteredText>{t('no-tickets-available')}</CenteredText>
        );
      case DISPLAY_MODE.TICKET_GROUPS:
        return ticketsIncludedInTicketGroups?.map((ticket: Ticket) => (
          <TicketCardItem
            key={ticket?.id}
            ticket={ticket}
            selectedTicketsWithOptions={selectedTicketsWithOptions}
            setSelectedTicketsWithOptions={setSelectedTicketsWithOptions}
            discount={
              checkIfDiscountAppliesMinMax(selectedTicketsWithOptions, discount)
                ? discount
                : undefined
            }
          />
        ));

      default:
        return tickets?.length > 0 ? (
          tickets?.map((ticket: Ticket) => (
            <TicketCardItem
              key={ticket?.id}
              ticket={ticket}
              selectedTicketsWithOptions={selectedTicketsWithOptions}
              setSelectedTicketsWithOptions={setSelectedTicketsWithOptions}
              discount={
                checkIfDiscountAppliesMinMax(
                  selectedTicketsWithOptions,
                  discount
                )
                  ? discount
                  : undefined
              }
            />
          ))
        ) : (
          <CenteredText>{t('no-tickets-available')}</CenteredText>
        );
    }
  }, [
    code,
    discount,
    displayPriority,
    groupTicket,
    selectedTicketsWithOptions,
    setSelectedTicketsWithOptions,
    t,
    ticketGroups,
    tickets,
    ticketsIncludedInTicketGroups,
  ]);

  useEffect(() => {
    if (eventDetails?.error) {
      setError(eventDetails.error);
    } else if (ticketsDetails?.error) {
      setError(ticketsDetails.error);
    } else if (paymentMethodsDetails?.error) {
      setError(paymentMethodsDetails.error);
    } else if (bookingsCheckDetails?.error) {
      setError(bookingsCheckDetails.error);
    } else if (ticketGroupsError) {
      setError(String(t('discount-not-valid')));
    }
  }, [
    bookingsCheckDetails.error,
    eventDetails.error,
    paymentMethodsDetails.error,
    t,
    ticketGroupsError,
    ticketsDetails.error,
  ]);

  useEffect(() => {
    if (!activeFilter && ticketGroups?.length > 0) {
      setActiveFilter(ticketGroups[0]);
    }
  }, [ticketGroups, activeFilter, setActiveFilter]);

  return (
    <Column gap="3.5rem">
      {!isIframe && (
        <EventDetails
          event={event}
          loading={!!isEventLoading}
          error={isEventError}
        />
      )}
      <ErrorSection description={error} />
      <CentralContent>
        <TicketConfigHeader>
          <RightSideContent>
            {displayPriority === DISPLAY_MODE.TICKET_GROUPS ? (
              <TabSwitcher items={tabNavigationTicketGroups} />
            ) : (
              <div />
            )}
          </RightSideContent>
          <LeftSideContent>
            <CurrencySwitcher>
              {currencies.length > 1 &&
                currencies.map(currency => {
                  return (
                    <Checkbox
                      key={currency}
                      spacing="0.2rem"
                      label={
                        <RowAlignedItems>
                          <PSmallBold>{t(currency)}</PSmallBold>
                        </RowAlignedItems>
                      }
                      checked={selectedCurrency === currency.toUpperCase()}
                      type="round"
                      backgroundColor={
                        selectedCurrency === currency.toUpperCase()
                          ? theme.color.greyDark
                          : theme.color.white
                      }
                      color={
                        selectedCurrency === currency.toUpperCase()
                          ? theme.color.white
                          : theme.color.transparent
                      }
                      border={
                        selectedCurrency === currency.toUpperCase()
                          ? `0.2rem solid ${theme.color.greyDark}`
                          : `0.2rem solid ${theme.color.greyMediumDark}`
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          setSearchParams({
                            ...extractSearchParams(searchParams),
                            cu: currency.toUpperCase(),
                          });
                        }
                      }}
                    />
                  );
                })}
            </CurrencySwitcher>
          </LeftSideContent>
        </TicketConfigHeader>
        <TicketCardItemWrapper data-testid="tickets:tickets-container">
          {isTicketsLoading ?? isTicketGroupLoading ? (
            <TicketSkeletonContainer numberOfSkeletons={3} />
          ) : (
            BookingTickets
          )}
          <DiscountSection
            selectedTicketsWithOptions={selectedTicketsWithOptions}
            discount={discount}
            eventDetails={eventDetails}
            setDiscount={setDiscount}
            setError={setError}
          />
        </TicketCardItemWrapper>
      </CentralContent>
    </Column>
  );
};

export default TicketDetailsStep;
