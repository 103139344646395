import { H6, P } from '@components/Typography';
import {
  CopyLink,
  PurchaseLinkWrapper,
} from '@pages/Purchase/components/PaymentDetailsStep/styles';
import { useCallback, useMemo } from 'react';

import { BoxDecoration } from '@components/BoxDecoration';
import Icon from '@components/Icon';
import { Column } from '@components/LinearLayout';
import { type PublicEvent } from '@hubs101/booking-api-skd-client/lib/types';
import { usePublicEvents } from '@utils/apiHooks';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const ShareEvent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const copyTextToClipboard = useCallback(() => {
    void window.navigator?.clipboard.writeText(
      'https://staging-booking.hubs101.com/purchase/big-booking-system-summit-dach'
    );
  }, []);

  const eventSlug = useMemo(() => {
    const pathNameUrl = window?.location.pathname?.split('/');
    return pathNameUrl.at(-1);
  }, []);

  const { data } = usePublicEvents({ page: 0, slug: eventSlug });

  const bookingEventDetails = data?.events?.result?.data?.[0] as PublicEvent;

  return (
    <BoxDecoration
      padding="2rem"
      bgColor={theme.color.white}
      borderRadius={theme.borderRadius.radius10}
      width="100%"
      boxShadow="0 0.2rem 0 rgba(0, 0, 0, 0.1)"
      border={`0.1rem solid ${theme.color.grey1}`}
    >
      <Column gap="3rem">
        <Column gap="1rem">
          <H6>{t('share-this-link-with-friends')}</H6>
          <PurchaseLinkWrapper>
            {bookingEventDetails?.slug && (
              <P data-testid="share-link">
                {`${window?.location?.origin}/purchase/${bookingEventDetails?.slug}?cu=EUR&la=en`}
              </P>
            )}
            <CopyLink onClick={copyTextToClipboard}>
              <Icon name="survey" size="2rem" color={theme.color.greyDark} />
            </CopyLink>
          </PurchaseLinkWrapper>
        </Column>
        <Column gap="1rem">
          <H6>{t('do-you-need-help')}</H6>
          <P
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t('ask-us-anything') ?? ''),
            }}
          />
        </Column>
      </Column>
    </BoxDecoration>
  );
};

export default ShareEvent;
